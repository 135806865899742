<template>
  <div>
    <p> nothing </p>
  </div>
</template>

<script lang="ts">

export default {
  mounted() {

  },
}

</script>